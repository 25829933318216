@import 'normalize.css';
@import 'antd/dist/antd.min.css';

/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: '思源宋体 ExtraLight';
  font-weight: 250;
  src: url('//at.alicdn.com/wf/webfont/l7qafrtUPBDf/rr0yAV3a-nxsNmB7rG51h.woff2')
      format('woff2'),
    url('//at.alicdn.com/wf/webfont/l7qafrtUPBDf/BSAz7K08A8a6Hrne-v1Yv.woff')
      format('woff');
  font-display: swap;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

html,
#root,
.app {
  width: 100%;
  height: 100%;
  .ant-layout-header {
    height: 50px;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
  }
  // 修改底部样式padding
  .ant-layout-footer {
    padding: 10px 40px;
  }
}

@import 'normalize.css';
@import 'antd/dist/antd.min.css';

/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: '思源宋体 ExtraLight';
  font-weight: 250;
  src: url('//at.alicdn.com/wf/webfont/l7qafrtUPBDf/rr0yAV3a-nxsNmB7rG51h.woff2')
      format('woff2'),
    url('//at.alicdn.com/wf/webfont/l7qafrtUPBDf/BSAz7K08A8a6Hrne-v1Yv.woff')
      format('woff');
  font-display: swap;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

html,
#root,
.app {
  width: 100%;
  height: 100%;
  .ant-layout-header {
    height: 50px;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
  }
  // 修改底部样式padding
  .ant-layout-footer {
    padding: 10px 40px;
  }
}
